@charset "UTF-8";
/*!
 * Bootstrap Grid v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1230px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1380px; } }

.container-fluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-mb-1, .col-mb-2, .col-mb-3, .col-mb-4, .col-mb-5, .col-mb-6, .col-mb-7, .col-mb-8, .col-mb-9, .col-mb-10, .col-mb-11, .col-mb-12, .col-mb,
.col-mb-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 30px;
  padding-left: 30px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-mb {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-mb-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-mb-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-mb-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-mb-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-mb-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-mb-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-mb-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-mb-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-mb-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-mb-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-mb-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-mb-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-mb-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-mb-first {
    order: -1; }
  .order-mb-last {
    order: 13; }
  .order-mb-0 {
    order: 0; }
  .order-mb-1 {
    order: 1; }
  .order-mb-2 {
    order: 2; }
  .order-mb-3 {
    order: 3; }
  .order-mb-4 {
    order: 4; }
  .order-mb-5 {
    order: 5; }
  .order-mb-6 {
    order: 6; }
  .order-mb-7 {
    order: 7; }
  .order-mb-8 {
    order: 8; }
  .order-mb-9 {
    order: 9; }
  .order-mb-10 {
    order: 10; }
  .order-mb-11 {
    order: 11; }
  .order-mb-12 {
    order: 12; }
  .offset-mb-0 {
    margin-left: 0; }
  .offset-mb-1 {
    margin-left: 8.33333%; }
  .offset-mb-2 {
    margin-left: 16.66667%; }
  .offset-mb-3 {
    margin-left: 25%; }
  .offset-mb-4 {
    margin-left: 33.33333%; }
  .offset-mb-5 {
    margin-left: 41.66667%; }
  .offset-mb-6 {
    margin-left: 50%; }
  .offset-mb-7 {
    margin-left: 58.33333%; }
  .offset-mb-8 {
    margin-left: 66.66667%; }
  .offset-mb-9 {
    margin-left: 75%; }
  .offset-mb-10 {
    margin-left: 83.33333%; }
  .offset-mb-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-mb-none {
    display: none !important; }
  .d-mb-inline {
    display: inline !important; }
  .d-mb-inline-block {
    display: inline-block !important; }
  .d-mb-block {
    display: block !important; }
  .d-mb-table {
    display: table !important; }
  .d-mb-table-row {
    display: table-row !important; }
  .d-mb-table-cell {
    display: table-cell !important; }
  .d-mb-flex {
    display: flex !important; }
  .d-mb-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-mb-row {
    flex-direction: row !important; }
  .flex-mb-column {
    flex-direction: column !important; }
  .flex-mb-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-mb-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-mb-wrap {
    flex-wrap: wrap !important; }
  .flex-mb-nowrap {
    flex-wrap: nowrap !important; }
  .flex-mb-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-mb-fill {
    flex: 1 1 auto !important; }
  .flex-mb-grow-0 {
    flex-grow: 0 !important; }
  .flex-mb-grow-1 {
    flex-grow: 1 !important; }
  .flex-mb-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-mb-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-mb-start {
    justify-content: flex-start !important; }
  .justify-content-mb-end {
    justify-content: flex-end !important; }
  .justify-content-mb-center {
    justify-content: center !important; }
  .justify-content-mb-between {
    justify-content: space-between !important; }
  .justify-content-mb-around {
    justify-content: space-around !important; }
  .align-items-mb-start {
    align-items: flex-start !important; }
  .align-items-mb-end {
    align-items: flex-end !important; }
  .align-items-mb-center {
    align-items: center !important; }
  .align-items-mb-baseline {
    align-items: baseline !important; }
  .align-items-mb-stretch {
    align-items: stretch !important; }
  .align-content-mb-start {
    align-content: flex-start !important; }
  .align-content-mb-end {
    align-content: flex-end !important; }
  .align-content-mb-center {
    align-content: center !important; }
  .align-content-mb-between {
    align-content: space-between !important; }
  .align-content-mb-around {
    align-content: space-around !important; }
  .align-content-mb-stretch {
    align-content: stretch !important; }
  .align-self-mb-auto {
    align-self: auto !important; }
  .align-self-mb-start {
    align-self: flex-start !important; }
  .align-self-mb-end {
    align-self: flex-end !important; }
  .align-self-mb-center {
    align-self: center !important; }
  .align-self-mb-baseline {
    align-self: baseline !important; }
  .align-self-mb-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-mb-0 {
    margin: 0 !important; }
  .mt-mb-0,
  .my-mb-0 {
    margin-top: 0 !important; }
  .mr-mb-0,
  .mx-mb-0 {
    margin-right: 0 !important; }
  .mb-mb-0,
  .my-mb-0 {
    margin-bottom: 0 !important; }
  .ml-mb-0,
  .mx-mb-0 {
    margin-left: 0 !important; }
  .m-mb-1 {
    margin: 0.25rem !important; }
  .mt-mb-1,
  .my-mb-1 {
    margin-top: 0.25rem !important; }
  .mr-mb-1,
  .mx-mb-1 {
    margin-right: 0.25rem !important; }
  .mb-mb-1,
  .my-mb-1 {
    margin-bottom: 0.25rem !important; }
  .ml-mb-1,
  .mx-mb-1 {
    margin-left: 0.25rem !important; }
  .m-mb-2 {
    margin: 0.5rem !important; }
  .mt-mb-2,
  .my-mb-2 {
    margin-top: 0.5rem !important; }
  .mr-mb-2,
  .mx-mb-2 {
    margin-right: 0.5rem !important; }
  .mb-mb-2,
  .my-mb-2 {
    margin-bottom: 0.5rem !important; }
  .ml-mb-2,
  .mx-mb-2 {
    margin-left: 0.5rem !important; }
  .m-mb-3 {
    margin: 1rem !important; }
  .mt-mb-3,
  .my-mb-3 {
    margin-top: 1rem !important; }
  .mr-mb-3,
  .mx-mb-3 {
    margin-right: 1rem !important; }
  .mb-mb-3,
  .my-mb-3 {
    margin-bottom: 1rem !important; }
  .ml-mb-3,
  .mx-mb-3 {
    margin-left: 1rem !important; }
  .m-mb-4 {
    margin: 1.5rem !important; }
  .mt-mb-4,
  .my-mb-4 {
    margin-top: 1.5rem !important; }
  .mr-mb-4,
  .mx-mb-4 {
    margin-right: 1.5rem !important; }
  .mb-mb-4,
  .my-mb-4 {
    margin-bottom: 1.5rem !important; }
  .ml-mb-4,
  .mx-mb-4 {
    margin-left: 1.5rem !important; }
  .m-mb-5 {
    margin: 3rem !important; }
  .mt-mb-5,
  .my-mb-5 {
    margin-top: 3rem !important; }
  .mr-mb-5,
  .mx-mb-5 {
    margin-right: 3rem !important; }
  .mb-mb-5,
  .my-mb-5 {
    margin-bottom: 3rem !important; }
  .ml-mb-5,
  .mx-mb-5 {
    margin-left: 3rem !important; }
  .p-mb-0 {
    padding: 0 !important; }
  .pt-mb-0,
  .py-mb-0 {
    padding-top: 0 !important; }
  .pr-mb-0,
  .px-mb-0 {
    padding-right: 0 !important; }
  .pb-mb-0,
  .py-mb-0 {
    padding-bottom: 0 !important; }
  .pl-mb-0,
  .px-mb-0 {
    padding-left: 0 !important; }
  .p-mb-1 {
    padding: 0.25rem !important; }
  .pt-mb-1,
  .py-mb-1 {
    padding-top: 0.25rem !important; }
  .pr-mb-1,
  .px-mb-1 {
    padding-right: 0.25rem !important; }
  .pb-mb-1,
  .py-mb-1 {
    padding-bottom: 0.25rem !important; }
  .pl-mb-1,
  .px-mb-1 {
    padding-left: 0.25rem !important; }
  .p-mb-2 {
    padding: 0.5rem !important; }
  .pt-mb-2,
  .py-mb-2 {
    padding-top: 0.5rem !important; }
  .pr-mb-2,
  .px-mb-2 {
    padding-right: 0.5rem !important; }
  .pb-mb-2,
  .py-mb-2 {
    padding-bottom: 0.5rem !important; }
  .pl-mb-2,
  .px-mb-2 {
    padding-left: 0.5rem !important; }
  .p-mb-3 {
    padding: 1rem !important; }
  .pt-mb-3,
  .py-mb-3 {
    padding-top: 1rem !important; }
  .pr-mb-3,
  .px-mb-3 {
    padding-right: 1rem !important; }
  .pb-mb-3,
  .py-mb-3 {
    padding-bottom: 1rem !important; }
  .pl-mb-3,
  .px-mb-3 {
    padding-left: 1rem !important; }
  .p-mb-4 {
    padding: 1.5rem !important; }
  .pt-mb-4,
  .py-mb-4 {
    padding-top: 1.5rem !important; }
  .pr-mb-4,
  .px-mb-4 {
    padding-right: 1.5rem !important; }
  .pb-mb-4,
  .py-mb-4 {
    padding-bottom: 1.5rem !important; }
  .pl-mb-4,
  .px-mb-4 {
    padding-left: 1.5rem !important; }
  .p-mb-5 {
    padding: 3rem !important; }
  .pt-mb-5,
  .py-mb-5 {
    padding-top: 3rem !important; }
  .pr-mb-5,
  .px-mb-5 {
    padding-right: 3rem !important; }
  .pb-mb-5,
  .py-mb-5 {
    padding-bottom: 3rem !important; }
  .pl-mb-5,
  .px-mb-5 {
    padding-left: 3rem !important; }
  .m-mb-auto {
    margin: auto !important; }
  .mt-mb-auto,
  .my-mb-auto {
    margin-top: auto !important; }
  .mr-mb-auto,
  .mx-mb-auto {
    margin-right: auto !important; }
  .mb-mb-auto,
  .my-mb-auto {
    margin-bottom: auto !important; }
  .ml-mb-auto,
  .mx-mb-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*
@name: Prefixed transition
@values: Variable values
@example:
  @include transition(transform);
*/
/*
@name: Prefixed transform
@values: Variable values
@example:
  @include transform(translateX(10px));
*/
/*
@name: Prefixed transform-origin
@values: Variable values
@example:
  @include transform(translateX(10px));
*/
/*
@name: Prefixed box-shadow
@values: Variable values
@example:
  @include box-shadow(0 1px 0 0 #ccddcc);
*/
/*
@name: Prefixed box-sizing
@values: Any value that box-sizing accepts
@example:
  @include box-sizing(border-box);
*/
/*
@name: Prefixed input placeholder
@values: Variable content
@example:
  @include placeholder {
    content ...;
  };
*/
/*
@name: Prefixed appearance
@values: Any value accepted by Appearance;
@example:
  @include appearance(none);
*/
/*
@name: Prefixed keyframes
@values: name, content;
@example:
  @include keyframes(fadeOut) {
    0%   { left: 0; }
    100% { left: 100px; }
  }
*/
/*
@name: Prefixed animation
@values: Animation name;
@example:
  @include animation(fadeOut);
*/
/*
@name: Prefixed border-radius
@values: Any values border-radius accepts;
@example:
  @include border-radius(2px);
*/
/*
@name: Prefixed opacity
@values: Non-hex numerical value;
@example:
  @include opacity(0.5);
*/
/*
@name: Linear gradients
@param: string Direction
        var Color stops
@example:
  @include linear-gradient("to top", #000, #eee);
*/
/*
@name: Fetch easing type
@param: var EaseType
@example:
  ease('fluid');
*/
/*
@name: Retina query
@param: Content
@example:
  @include retina {
    content ...;
  }
*/
/*
@name: Font-face
@param: string Name
        string Path
        int Weight
        var Style
        string Exts
@example:
  @include font-face(SuperSans, "path/to/font", $weight: normal, $style: normal, ttf);
*/
/*
@name: Helper functions
*/
@font-face {
  font-family: "Avenir";
  src: url("{{ get_asset_url('../fonts/Metropolis-Regular.otf') }}") format("opentype");
  font-weight: 400;
  font-style: normal; }

* {
  font-family: "Avenir", "Roboto", sans-serif; }

@-webkit-keyframes reveal {
  0% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100";
    filter: alpha(opacity=100); } }

@-moz-keyframes reveal {
  0% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100";
    filter: alpha(opacity=100); } }

@-ms-keyframes reveal {
  0% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100";
    filter: alpha(opacity=100); } }

@-o-keyframes reveal {
  0% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100";
    filter: alpha(opacity=100); } }

@keyframes reveal {
  0% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100";
    filter: alpha(opacity=100); } }

.has-js .lazy-image--pending {
  min-height: 100px;
  position: relative;
  display: block;
  overflow: hidden;
  outline: none; }
  .has-js .lazy-image--pending img {
    filter: blur(2vw);
    transform: scale(1.05); }

.has-js .lazy-image--loaded {
  min-height: 100px;
  position: relative;
  display: block;
  overflow: hidden;
  outline: none; }

.has-js .lazy-image__actual {
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform, opacity; }

.has-js .lazy-image img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
  border: 0 none; }

.no-js .no-js-fallback {
  position: relative; }
  .no-js .no-js-fallback .lazy-image {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0";
    filter: alpha(opacity=0); }

.fade-in {
  -webkit-animation: reveal 1.2s cubic-bezier(0.3, 0, 0.22, 1);
  -moz-animation: reveal 1.2s cubic-bezier(0.3, 0, 0.22, 1);
  -ms-animation: reveal 1.2s cubic-bezier(0.3, 0, 0.22, 1);
  -o-animation: reveal 1.2s cubic-bezier(0.3, 0, 0.22, 1);
  animation: reveal 1.2s cubic-bezier(0.3, 0, 0.22, 1); }

body {
  color: #00293a; }

h1,
.h1 {
  font-size: 64px;
  line-height: 70px;
  font-weight: 700; }

h2,
.h2 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700; }

h3,
.h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700; }

h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700; }

h5,
.h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700; }

h6,
.h6 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700; }

.p1 {
  font-size: 21px;
  line-height: 31px;
  font-weight: 500; }

.p2 {
  font-size: 16px;
  line-height: 24px; }

.p3 {
  font-size: 12px;
  line-height: 27px; }

.eyebrow2 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase; }

.eyebrow3 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase; }

.button {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 24px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid; }

a {
  color: #4098d3;
  text-decoration: none; }

@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 45px;
    line-height: 1.16; }
  h2,
  .h2 {
    font-size: 35px;
    line-height: 1.16; } }

.alignnone {
  margin: 5px 20px 20px 0; }

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

a img.alignnone {
  margin: 5px 20px 20px 0; }

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  padding: 5px 3px 10px;
  text-align: center; }

.wp-caption.alignnone {
  margin: 5px 20px 20px 0; }

.wp-caption.alignleft {
  margin: 5px 20px 20px 0; }

.wp-caption.alignright {
  margin: 5px 0 20px 20px; }

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto; }

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px; }

.clearfix {
  zoom: 1; }
  .clearfix::after {
    display: table;
    clear: both;
    content: ' '; }

.center {
  margin: 0 auto; }

*,
*::after,
*::before {
  box-sizing: inherit; }

.contain-grid {
  padding-left: 150px;
  padding-right: 150px; }

@media screen and (max-width: 1599px) {
  .contain-grid {
    padding-left: fluid-unit(1679px, 1440px, 150px, 75px);
    padding-right: fluid-unit(1679px, 1440px, 150px, 75px); } }

@media screen and (max-width: 1440px) {
  .contain-grid {
    padding-left: 75px;
    padding-right: 75px; } }

@media screen and (max-width: 575px) {
  .contain-grid {
    padding-left: 35px;
    padding-right: 35px; } }

@media screen and (max-width: 767px) {
  .contain-grid {
    padding-left: 5px;
    padding-right: 5px; } }

.text-right {
  text-align: right; }

@font-face {
  font-family: "avis-fleet-icons";
  src: url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.eot?c3m5zw");
  src: url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.eot?c3m5zw#iefix") format("embedded-opentype"), url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.woff2?c3m5zw") format("woff2"), url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.ttf?c3m5zw") format("truetype"), url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.woff?c3m5zw") format("woff"), url("https://d1azc1qln24ryf.cloudfront.net/128885/AVISFleet/avis-fleet-icons.svg?c3m5zw#avis-fleet-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "avis-fleet-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-commercial_vehicle:before {
  content: "\e915"; }

.icon-accident_management:before {
  content: "\e901"; }

.icon-commercial_vehicles:before,
.icon-short_term_car_rental:before {
  content: "\e902"; }

.icon-driver_360:before {
  content: "\e903"; }

.icon-engine_rebates:before {
  content: "\e904"; }

.icon-fleet_accident_management:before {
  content: "\e905"; }

.icon-fleet_administration:before,
.icon-insurance:before {
  content: "\e906"; }

.icon-fleet_consulting:before {
  content: "\e907"; }

.icon-fleet_interactive:before {
  content: "\e908"; }

.icon-fleet_procurement:before {
  content: "\e909"; }

.icon-fleet_reporting:before {
  content: "\e90a"; }

.icon-fuel_cards:before {
  content: "\e90b"; }

.icon-fuel_management:before,
.icon-intelligent_fuel_management:before {
  content: "\e90c"; }

.icon-fuel_remote_web:before {
  content: "\e90d"; }

.icon-full_maintenance_lease:before {
  content: "\e90e"; }

.icon-managed_maintenance:before {
  content: "\e90f"; }

.icon-open_road_tolling:before {
  content: "\e910"; }

.icon-quote_marks:before {
  content: "\e911"; }

.icon-roadside_assistance:before {
  content: "\e912"; }

.icon-service_and_maintenance_two:before,
.icon-service_and_maintenance:before {
  content: "\e913"; }

.icon-service_and_maintenance_1:before {
  content: "\e900"; }

.icon-telematics:before {
  content: "\e914"; }

.icon-traffic_fine_management:before,
.icon-traffic_fine_management_solution:before {
  content: "\e916"; }

.icon-vehicle_disposal:before {
  content: "\e917"; }

.icon-vehicle_ownership:before {
  content: "\e918"; }

.icon-addition_icon:before {
  content: "\e919"; }

.icon-avisfleet_iconography_log_in_icon_01:before {
  content: "\e91a"; }

.icon-avisfleet_iconography_log_in_icon_02:before {
  content: "\e91b"; }

.icon-bookmark_active_state_icon:before {
  content: "\e91c"; }

.icon-bookmark_inactive_state:before {
  content: "\e91d"; }

.icon-exit_icon:before {
  content: "\e91e"; }

.icon-facebook_icon_blogs_and_trends:before {
  content: "\e91f"; }

.icon-facebook_icon:before {
  content: "\e920"; }

.icon-long_arrow_down:before {
  content: "\e921"; }

.icon-long_arrow_up:before {
  content: "\e922"; }

.icon-short_arrow_left:before {
  content: "\e923"; }

.icon-short_arrow_right:before {
  content: "\e924"; }

.icon-short_arrow_upload:before {
  content: "\e925"; }

.icon-linked_in_icon_blogs_and_trends:before {
  content: "\e926"; }

.icon-linked_in_icon:before {
  content: "\e927"; }

.icon-long_arrow_left:before {
  content: "\e928"; }

.icon-long_arrow_right:before {
  content: "\e929"; }

.icon-search_icon:before {
  content: "\e92a"; }

.icon-secondary_exit_icon:before {
  content: "\e92b"; }

.icon-share_icon:before {
  content: "\e92c"; }

.icon-short_arrow_download:before {
  content: "\e92d"; }

.icon-short_term_rental:before,
.icon-short_term_rentals:before {
  content: "\e92e"; }

.icon-twitter_icon_blogs_and_trends:before {
  content: "\e92f"; }

.icon-twitter_icon:before {
  content: "\e930"; }

.icon-twitter:before {
  content: ""; }

* {
  box-sizing: border-box !important; }

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: normal;
  line-height: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

.main {
  margin-top: 175px; }

.not-padded {
  padding: 0; }

.landing_page .navigation {
  display: none !important; }

.background-black .newsletter__form .gform_wrapper .gfield input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.background-black .newsletter__form .gform_wrapper .gfield textarea,
.background-black .newsletter__form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.background-black .newsletter__form textarea {
  border-color: #818488; }

.header {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  overflow-x: hidden; }
  .header::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }
  .header .container {
    position: relative;
    z-index: 51; }
  .header__logo img {
    width: 150px; }
  .header__nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; }
    .header__nav ul li {
      margin: 0 4.375rem 0 0;
      transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1);
      cursor: pointer; }
      .header__nav ul li a {
        font-size: 1.25rem;
        line-height: 1.4;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
      .header__nav ul li.hs-item-has-children {
        position: relative; }
        .header__nav ul li.hs-item-has-children::after {
          content: "";
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          border-bottom: 0;
          color: #fff; }
        .header__nav ul li.hs-item-has-children .hs-menu-children-wrapper {
          display: none;
          position: absolute;
          padding-left: 0;
          left: 50%;
          transform: translateX(-50%);
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 10px 0; }
          .header__nav ul li.hs-item-has-children .hs-menu-children-wrapper li {
            margin: 0;
            opacity: 1; }
            .header__nav ul li.hs-item-has-children .hs-menu-children-wrapper li a {
              opacity: 1; }
            .header__nav ul li.hs-item-has-children .hs-menu-children-wrapper li:hover a {
              opacity: 0.8; }
        .header__nav ul li.hs-item-has-children:hover .hs-menu-children-wrapper {
          display: flex; }
      .header__nav ul li:hover a {
        opacity: 0.8; }
  .header__nav-mobile {
    display: none; }
  .header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .header__social {
    display: flex;
    align-items: center; }
    .header__social__link {
      margin-right: 1rem;
      display: block;
      width: 100%;
      height: 100%;
      transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
      .header__social__link:hover {
        opacity: 0.8; }
      .header__social__link:last-of-type {
        margin-right: 0px; }
      .header__social__link img {
        width: 30px;
        height: 30px; }
  .header__login {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: #fff;
    padding: 0.5rem 4.375rem 0.4375rem 1.25rem;
    font-size: 1.3125rem;
    line-height: 1.14;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22157.121%22%20height%3D%2238.004%22%20viewBox%3D%220%200%20157.121%2038.004%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M138.121.005h-1v-.01c-.23%200-.471%200-.701.01H19c-10.49%200-19%208.5-19%2019%200%2010.49%208.51%2019%2019%2019h119.121c10.489%200%2019-8.51%2019-19%200-10.5-8.511-19-19-19zm0%2035.999h-1c-6.852%200-13-4.08-15.66-10.379l-1.851.77a18.985%2018.985%200%200%200%209.011%209.609H19c-9.37%200-17-7.629-17-17%200-9.38%207.63-17%2017-17h109.63a19.003%2019.003%200%200%200-8.871%209.27l1.82.81c2.65-5.94%208.45-9.84%2014.91-10.08h1.631c9.369%200%2017%207.62%2017%2017%20.001%209.371-7.63%2017-16.999%2017z%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M133.96%2013.75l4.778%204.778H106.08v2h32.656l-4.776%204.777%201.415%201.414%207.19-7.192-7.19-7.191z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-weight: 900;
    margin-left: 30px;
    transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1);
    letter-spacing: 1px; }
    .header__login:hover {
      opacity: 0.8; }
    .header__login__dropdown {
      position: absolute;
      top: 105px;
      right: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid transparent;
      border-radius: 0;
      outline: 0;
      padding: 0.8rem;
      max-width: 210px;
      opacity: 0;
      transform: translate(0, -50px);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      z-index: -5;
      pointer-events: none; }
      .header__login__dropdown.open {
        transform: none;
        opacity: 1;
        z-index: 101;
        pointer-events: all; }
      .header__login__dropdown select {
        margin: 1rem 0;
        border-radius: 3px;
        color: #777;
        font-size: 0.8rem;
        -webkit-font-smoothing: antialiased;
        -webkit-appearance: none !important;
        background-color: #fafafa;
        background-image: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9z�UiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position: 97% center;
        border: 1px solid #cccccc;
        padding: 0.6rem;
        outline: none;
        text-transform: uppercase; }
      .header__login__dropdown button {
        color: #fff;
        background-color: #cc2027;
        padding: 0.35rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.55;
        border-radius: 0;
        font-weight: 700;
        letter-spacing: 1px;
        border: none;
        cursor: pointer;
        transition: 0.25s background-color cubic-bezier(0.3, 0, 0.22, 1); }
        .header__login__dropdown button:hover {
          background-color: #ab1b21; }
    .header__login__dropdown-title {
      color: #cc2027;
      font-size: 1.04rem;
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: 900; }
  .header__login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.5); }
    .header__login-overlay.open {
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 17px;
      display: block; }
  .header__hamburger {
    display: none; }

@media screen and (max-width: 1599px) {
  .header .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .header__nav ul li {
    margin: 0 2rem 0 0; } }

@media screen and (max-width: 1400px) {
  .header__nav {
    margin-right: 160px; }
    .header__nav ul {
      display: flex;
      align-items: start; }
      .header__nav ul li {
        margin: 0 1.4rem 0 0; }
        .header__nav ul li a {
          font-size: 1rem; } }

@media screen and (min-width: 991px) {
  .header .container {
    max-width: none;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    position: relative;
    z-index: 51; } }

@media screen and (max-width: 991px) {
  .header {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .header__logo {
      position: relative;
      z-index: 11; }
      .header__logo img {
        margin-top: 8px; }
    .header__social__link img {
      width: 29px;
      height: 29px; }
    .header__login {
      padding: 0.4375rem 3.5rem 0.4375rem 1.125rem;
      font-size: 1.125rem;
      line-height: 1.111;
      margin-left: 20px;
      letter-spacing: 0.7px; }
      .header__login__dropdown {
        top: 92px;
        right: 10%; }
    .header__hamburger {
      width: 1.9375rem;
      height: 1.9375rem;
      white-space: nowrap;
      display: block;
      margin-left: 0.625rem;
      padding: 0.3125rem 0.8125rem;
      position: relative;
      text-indent: -9999px;
      line-height: 0;
      z-index: 102;
      border: 0.125rem solid #fff;
      border-radius: 0;
      overflow: hidden;
      background: #fff; }
      .header__hamburger::before {
        transition: transform 0.2s linear, top 0.2s linear, left 0.2s linear, right 0.2s linear;
        content: "";
        background: #262626;
        position: absolute;
        height: 0.1875rem;
        top: 0.25rem;
        left: 0.1875rem;
        right: 0.25rem; }
      .header__hamburger span {
        transition: transform 0.2s linear, top 0.2s linear, left 0.2s linear, right 0.2s linear;
        content: "";
        background: #262626;
        position: absolute;
        height: 0.1875rem;
        top: 0.75rem;
        left: 0.625rem;
        right: 0.125rem; }
      .header__hamburger::after {
        transition: transform 0.2s linear, top 0.2s linear, left 0.2s linear, right 0.2s linear;
        content: "";
        background: #262626;
        position: absolute;
        height: 0.1875rem;
        top: 1.1875rem;
        left: 0.5rem;
        right: 0.5rem; }
      .header__hamburger.open {
        border-radius: 100%;
        background: transparent; }
        .header__hamburger.open::before {
          top: 0.8125rem;
          left: 0.3125rem;
          right: 0.3125rem;
          height: 0.125rem;
          background: #fff;
          transform: rotate(45deg); }
        .header__hamburger.open span {
          opacity: 0; }
        .header__hamburger.open::after {
          top: 0.8125rem;
          left: 0.3125rem;
          right: 0.3125rem;
          height: 0.125rem;
          background: #fff;
          transform: rotate(-45deg); }
    .header__nav-mobile {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 730px;
      max-width: 100%;
      top: -2.1875rem;
      background: rgba(0, 0, 0, 0.95);
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      max-height: 0;
      overflow: hidden;
      z-index: 10;
      transition: max-height 300ms cubic-bezier(0.3, 0, 0.22, 1); }
      .header__nav-mobile ul {
        text-align: center;
        padding: 12.8125rem 1.5625rem 10.625rem 1.5625rem;
        font-weight: 500;
        font-size: 1.5rem;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin-left: auto !important;
        margin-bottom: 0;
        list-style: none;
        text-transform: uppercase;
        line-height: 1.75; }
        .header__nav-mobile ul li {
          margin-bottom: 1.6875rem;
          letter-spacing: 0.7px; }
          .header__nav-mobile ul li a {
            color: #fff; }
          .header__nav-mobile ul li.hs-item-has-children {
            position: relative;
            width: max-content;
            margin-left: auto;
            margin-right: auto; }
            .header__nav-mobile ul li.hs-item-has-children::after {
              content: "";
              display: inline-block;
              margin-left: 0.255em;
              vertical-align: 0.255em;
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              border-bottom: 0;
              color: #fff;
              position: absolute;
              right: -24px;
              top: 18px; }
            .header__nav-mobile ul li.hs-item-has-children .hs-menu-children-wrapper {
              padding: 0;
              display: none; }
      .header__nav-mobile.open {
        max-height: 1000px; } }

@media screen and (max-width: 767px) {
  .header__logo img {
    width: 120px; } }

@media (max-width: 575px) {
  .header__social {
    display: none; }
  .header__login {
    font-size: 0.75rem;
    line-height: 1.3333;
    padding: 0.1875rem 2.5rem 0.1875rem 0.75rem; } }

.footer {
  padding: 5rem 0 6.25rem;
  background: #303030;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-align: center;
  font-weight: 500; }
  .footer .container {
    max-width: 1200px; }
  .footer a {
    color: rgba(255, 255, 255, 0.7);
    transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1); }
    .footer a:hover {
      color: white; }
  .footer__socials {
    margin-bottom: 2.8125rem; }
  .footer__social {
    width: 30px;
    height: 30px;
    margin-right: 0.935rem;
    opacity: 0.7;
    transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
    .footer__social:hover {
      opacity: 1; }
    .footer__social:last-child {
      margin-right: 0; }
  .footer__menu {
    margin-bottom: 3.4375rem; }
    .footer__menu ul {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .footer__menu ul li {
        font-size: 18px; }
        .footer__menu ul li.hs-item-has-children {
          position: relative;
          padding-right: 1.875rem; }
          .footer__menu ul li.hs-item-has-children::after {
            display: inline-block;
            vertical-align: 0.255em;
            content: "";
            width: 0.9375rem;
            height: 0.9375rem;
            position: absolute;
            top: -3px;
            right: 0;
            margin: 0;
            border: none;
            border-left: 0.125rem solid #fff;
            border-bottom: 0.125rem solid #fff;
            transform: rotate(-45deg);
            border-color: rgba(255, 255, 255, 0.6);
            cursor: pointer; }
          .footer__menu ul li.hs-item-has-children .hs-menu-children-wrapper {
            position: absolute;
            display: none !important;
            z-index: 10;
            min-width: 10rem;
            color: #212529;
            text-align: left;
            list-style: none;
            right: auto;
            bottom: auto;
            padding: 0.625rem 1rem;
            background: #343434;
            font-size: 0.9375rem;
            line-height: 1.42;
            font-weight: 500;
            width: 12.875rem;
            left: 50% !important;
            margin: 0.9375rem 0 0.9375rem -6.4375rem;
            top: -31px; }
            .footer__menu ul li.hs-item-has-children .hs-menu-children-wrapper li {
              padding: 0.625rem 0;
              border-bottom: 1px solid rgba(237, 237, 237, 0.2);
              text-transform: none; }
              .footer__menu ul li.hs-item-has-children .hs-menu-children-wrapper li a {
                color: rgba(255, 255, 255, 0.7);
                font-weight: 500;
                font-size: 15px;
                transition: color 250ms cubic-bezier(0.3, 0, 0.22, 1); }
                .footer__menu ul li.hs-item-has-children .hs-menu-children-wrapper li a span {
                  display: block;
                  font-weight: 900;
                  font-size: 15px; }
                .footer__menu ul li.hs-item-has-children .hs-menu-children-wrapper li a:hover {
                  color: #fff; }
          .footer__menu ul li.hs-item-has-children.active::after {
            width: 1.25rem;
            height: 0.125rem;
            top: 7px;
            border-left: none;
            transform: rotate(0); }
          .footer__menu ul li.hs-item-has-children.active .hs-menu-children-wrapper {
            display: block !important;
            transform: translate3d(0px, -430px, 0px); }
  .footer__copyright {
    margin-bottom: 3.75rem;
    line-height: 1.7; }

@media screen and (max-width: 991px) {
  .footer__menu ul {
    display: block; }
    .footer__menu ul li {
      margin-bottom: 10px; } }

@media (max-width: 991px) {
  .footer__menu ul li {
    width: max-content;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .footer__menu ul li.hs-item-has-children.active .hs-menu-children-wrapper {
      transform: translate3d(0px, -480px, 0px); }
    .footer__menu ul li.hs-item-has-children ul li {
      margin-left: 0;
      margin-right: 0; } }

main .container-fluid {
  padding-left: 0;
  padding-right: 0; }

input,
select,
textarea {
  appearance: none;
  -webkit-appearance: none; }

body {
  overflow-x: hidden; }

html,
body {
  scroll-behavior: smooth; }

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; }
  .loader-wrapper .loader {
    transform: scale(5);
    margin-left: 20px; }
  .loader-wrapper svg path,
  .loader-wrapper svg rect {
    fill: #cc2027; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.hero {
  height: 470px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  backdrop-filter: grayscale(30%); }
  .hero.big {
    height: 800px; }
  .hero::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); }
  .hero__title {
    line-height: 1.16;
    font-weight: 500;
    max-width: 31.25rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .hero .container {
    height: 100%; }
  .hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;
    position: relative; }
  .hero__link {
    line-height: 1.4;
    font-weight: 900;
    letter-spacing: 0.8px;
    font-size: 1.25rem;
    transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
    .hero__link:hover {
      opacity: 0.7; }
    .hero__link img {
      max-width: 40px; }

@media screen and (max-width: 1599px) {
  .hero.big {
    height: 650px; } }

@media screen and (max-width: 991px) {
  .hero {
    height: 450px; }
    .hero__title {
      max-width: 100%;
      font-size: 1.875rem;
      font-weight: 300; } }

.dropdown-links {
  position: relative;
  z-index: 3; }
  .dropdown-links__dropdown {
    max-width: 60rem;
    margin: 0 auto;
    z-index: 1;
    background: #fff;
    position: relative;
    box-shadow: 0 0 1.25rem -0.1875rem rgba(49, 50, 50, 0.1); }
    .dropdown-links__dropdown::before {
      content: "";
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      opacity: 0;
      pointer-events: none;
      transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
    .dropdown-links__dropdown.show::before {
      opacity: 1;
      pointer-events: all; }
  .dropdown-links__toggle {
    display: block;
    padding: 1.375rem 3.75rem 1.3125rem;
    width: 100%;
    outline: none;
    border: none;
    background: #fff;
    color: #a2a3a3;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    z-index: 1;
    font-size: 2.1875rem;
    line-height: 1.74;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .dropdown-links__toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      position: absolute;
      top: 50%;
      right: 3.875rem;
      border-left: 0.125rem solid #989898;
      border-bottom: 0.125rem solid #989898;
      transform: rotate(-45deg);
      width: 1.4375rem;
      height: 1.4375rem;
      margin-top: -1.0625rem;
      transition: 200ms all cubic-bezier(0.3, 0, 0.22, 1); }
    .dropdown-links__toggle.show::after {
      width: 1.5625rem;
      height: 0.125rem;
      border-left: none;
      transform: rotate(0);
      margin-top: 0; }
  .dropdown-links__menu {
    display: none;
    width: 100%;
    background: #fff;
    position: absolute;
    top: -100%;
    z-index: 30; }
    .dropdown-links__menu.show {
      display: block; }
    .dropdown-links__menu a {
      display: block;
      padding: 0.9375rem 1.875rem 0.9375rem 2.8125rem;
      color: #4a4d4e;
      font-size: 1.3125rem;
      line-height: 1.76;
      border-bottom: 1px solid #f0f0f0;
      transition: 300ms color cubic-bezier(0.3, 0, 0.22, 1), 300ms background-color cubic-bezier(0.3, 0, 0.22, 1); }
      .dropdown-links__menu a:hover {
        color: #fff;
        background-color: #cc2027; }

@media screen and (max-width: 991px) {
  .dropdown-links__toggle {
    font-size: 1.56rem; } }

@media screen and (max-width: 767px) {
  .dropdown-links__toggle {
    padding: 1rem 0.9375rem 1.0625rem;
    line-height: 1.45; }
    .dropdown-links__toggle::after {
      right: 1.875rem; }
  .dropdown-links__menu a {
    padding: 0.625rem 1.25rem 0.625rem 0.9375rem;
    white-space: normal;
    font-size: 15px;
    line-height: 1.33333; } }

.solutions {
  position: relative;
  background-size: 2.5rem 2.5rem;
  background-repeat: repeat; }
  .solutions .container.text-block-container {
    max-width: 1650px;
    position: relative; }
  .solutions::before {
    content: "";
    position: absolute;
    z-index: -2;
    bottom: 0;
    right: 0;
    top: 10.1875rem;
    left: calc(50% - 652px);
    background: rgba(0, 0, 0, 0.02); }
  .solutions__text-holder-bg {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -9999px;
    right: calc(50% - 189px);
    background: #cc2027;
    z-index: 0; }
  .solutions__text-holder {
    padding: 9.75rem 3.75rem 5rem 0;
    color: #fff;
    line-height: 1.75; }
    .solutions__text-holder__subheading {
      margin-bottom: 0.5rem;
      opacity: 0.5;
      color: inherit;
      letter-spacing: 0.3em;
      font-weight: 900; }
    .solutions__text-holder__heading {
      margin-bottom: 1.875rem; }
    .solutions__text-holder__description p {
      font-size: 1.25rem; }
  .solutions__items {
    margin-top: -1.875rem; }
    .solutions__items .container {
      position: relative; }
  .solutions__slider {
    counter-reset: item-counter; }
  .solutions__item {
    box-shadow: 0 0 20px -3px rgba(49, 50, 50, 0.1);
    background: #fff;
    margin-left: 1.875rem; }
    .solutions__item__name {
      padding: 2.75rem 5.3125rem 2.75rem 0;
      margin-left: -1.875rem;
      font-size: 1.75rem;
      min-height: 144px;
      font-weight: 300;
      color: #4c4c4c;
      position: relative; }
      .solutions__item__name::before {
        counter-increment: item-counter;
        content: counter(item-counter, decimal-leading-zero) " ";
        font-size: 2.5rem;
        line-height: 1;
        font-weight: 900;
        color: #cc2027;
        position: absolute;
        right: 1.375rem;
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0; }
    .solutions__item__description {
      padding: 35px 27px 20px 29px;
      margin-right: 1.875rem; }
    .solutions__item__description-title {
      letter-spacing: 0.3em;
      opacity: 0.7;
      position: relative;
      overflow: hidden;
      font-size: 15px;
      line-height: 1.47;
      margin-bottom: 1.25rem;
      font-weight: 900; }
      .solutions__item__description-title::after {
        content: "";
        position: absolute;
        bottom: 0.625rem;
        margin-left: 0.3125rem;
        width: 100%;
        border-bottom: 0.125rem dotted;
        border-color: inherit; }
    .solutions__item__description-text {
      font-size: 17px;
      line-height: 1.29;
      margin-bottom: 13px; }
    .solutions__item__description-link {
      transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1);
      display: block;
      width: 100%;
      color: #fff;
      font-size: 1rem;
      line-height: 1.22222;
      font-weight: 900;
      letter-spacing: 0.4px; }
      .solutions__item__description-link:hover {
        opacity: 0.8; }
      .solutions__item__description-link img {
        margin-left: 5px; }
    .solutions__item__info {
      padding: 33px 29px 28px;
      background: #fff; }
      .solutions__item__info ul {
        padding-left: 20px; }
        .solutions__item__info ul li {
          color: #777777;
          font-weight: 300;
          font-size: 1.25rem;
          margin-bottom: 0.1875rem;
          line-height: 1.75; }
    .solutions__item__info-title {
      letter-spacing: 0.3em;
      opacity: 0.7;
      position: relative;
      overflow: hidden;
      font-size: 15px;
      line-height: 1.47;
      margin-bottom: 1.25rem;
      font-weight: 900;
      color: #333333; }
      .solutions__item__info-title::after {
        content: "";
        position: absolute;
        bottom: 0.625rem;
        margin-left: 0.3125rem;
        width: 100%;
        border-bottom: 0.125rem dotted;
        border-color: inherit; }
    .solutions__item__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: #cc2027;
      color: #fff;
      padding: 0.9375rem 1.125rem;
      font-size: 1.125rem;
      line-height: 1.22222;
      text-align: center;
      font-weight: 900;
      letter-spacing: 0.4px;
      transition: 250ms background cubic-bezier(0.3, 0, 0.22, 1), 250ms color cubic-bezier(0.3, 0, 0.22, 1); }
      .solutions__item__link img {
        margin-left: 5px; }
      .solutions__item__link:hover {
        color: #fff;
        background: #ab1b21; }
  .solutions__bottom-link {
    margin-left: 1.875rem;
    color: #fff;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9375rem 1.125rem;
    font-size: 17px;
    line-height: 1.22222;
    font-weight: 900;
    max-width: max-content;
    letter-spacing: 0.8px;
    transition: 250ms background cubic-bezier(0.3, 0, 0.22, 1), 250ms color cubic-bezier(0.3, 0, 0.22, 1); }
    .solutions__bottom-link:hover {
      color: #fff;
      background: #131313; }
    .solutions__bottom-link::after {
      content: "+";
      position: relative;
      margin-left: 5px;
      font-weight: 400;
      font-size: 19px; }
  .solutions__slider-pages {
    display: none; }
  .solutions__slider-navigation {
    position: absolute;
    top: -50px;
    right: 0;
    display: none;
    align-items: center;
    z-index: 3; }
    .solutions__slider-navigation .slide-btn {
      margin: 0 10px;
      cursor: pointer;
      width: 29px;
      height: 34px; }
      .solutions__slider-navigation .slide-btn svg {
        object-fit: contain;
        width: 100%;
        height: 100%; }
        .solutions__slider-navigation .slide-btn svg path {
          stroke: #000; }
      .solutions__slider-navigation .slide-btn.slide-prev {
        transform: rotateY(180deg); }
      .solutions__slider-navigation .slide-btn.swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none; }

@media (max-width: 1279px) {
  .solutions__slider-navigation {
    display: flex !important; } }

@media (max-width: 991px) {
  .solutions__text-holder {
    padding: 5rem 3.75rem 5rem 0; }
  .solutions__item__info ul li {
    line-height: 1.5; }
  .solutions__bottom-link {
    margin: 0 !important;
    margin-bottom: 60px !important;
    margin-left: 28px !important; }
  .solutions__item {
    margin-left: 28px; } }

@media (max-width: 767px) {
  .solutions__slider-pages {
    display: block;
    position: absolute;
    top: -42px;
    left: 50px;
    font-weight: 900;
    font-size: 1.125rem;
    color: #fff;
    opacity: 0.2;
    display: inline-block; }
  .solutions__text-holder__description p {
    font-size: 1rem; }
  .solutions__text-holder__subheading {
    font-size: 0.75rem;
    margin-bottom: 0.875rem; }
  .solutions__text-holder__heading {
    margin-bottom: 0.9375rem; }
  .solutions__text-holder-bg {
    right: calc(50% - 240px); }
  .solutions__slider-navigation {
    right: 42px; }
    .solutions__slider-navigation .slide-btn svg path {
      stroke: #fff; } }

.posts {
  padding-bottom: 7.5rem;
  position: relative; }
  .posts__first-row {
    padding-top: 6.875rem;
    position: relative;
    overflow: hidden; }
    .posts__first-row::before {
      content: "";
      position: absolute;
      z-index: -2;
      height: 100%;
      left: calc(50% - 160px);
      top: 0;
      right: 0;
      background: #4a4d4e; }
    .posts__first-row::after {
      content: "";
      position: absolute;
      z-index: -2;
      top: 17.9375rem;
      height: 65rem;
      width: 21.25rem;
      right: 1.5625rem;
      background: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}") repeat;
      background-size: 2.5rem 2.5rem; }
  .posts__title-block {
    margin-bottom: 5.125rem; }
  .posts__subheading {
    margin-bottom: 0.9375rem;
    opacity: 0.5;
    font-size: 18px;
    letter-spacing: 0.3em;
    color: #fff;
    font-weight: 900; }
  .posts__heading {
    font-size: 3.125rem;
    line-height: 1.1;
    color: #fff;
    letter-spacing: 0.8px; }
  .posts__card {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 25rem;
    overflow: hidden; }
    .posts__card.card-regular ~ .posts__card.card-regular {
      margin-top: 3.75rem; }
    .posts__card__bg {
      transition: transform 0.25s cubic-bezier(0.3, 0, 0.22, 1);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12.5rem;
      color: rgba(255, 255, 255, 0.05); }
    .posts__card__body {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 26px;
      max-height: 100%;
      flex: 1 1 auto; }
      .posts__card__body a {
        color: #fff;
        transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1); }
        .posts__card__body a:hover {
          color: #caccce; }
      .posts__card__body h3 {
        font-size: 1.875rem;
        margin-bottom: 1.25rem;
        line-height: 1.2; }
    .posts__card:hover .posts__card__bg {
      transform: scale(1.1); }
    .posts__card__dropdown-toggle {
      width: 33px;
      height: 33px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer; }
    .posts__card__dropdown {
      display: none;
      background: #fff;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      background-color: #fff;
      border-radius: 1.25rem;
      padding: 0 0.625rem;
      margin-right: 0.625rem;
      color: #cc2027;
      display: none; }
      .posts__card__dropdown a {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center; }
        .posts__card__dropdown a svg {
          width: 15px;
          height: 15px;
          margin: 0 4px; }
          .posts__card__dropdown a svg path {
            fill: #000; }
          .posts__card__dropdown a svg.fb-icon {
            width: 20px;
            height: 19px; }
    .posts__card__footer {
      padding: 25px; }
      .posts__card__footer.dropdown-active .posts__card__dropdown {
        display: flex; }
    .posts__card.card-lg {
      min-height: 53.75rem;
      height: 100%;
      padding-top: 120px; }
      .posts__card.card-lg .posts__card__bg::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1; }
      .posts__card.card-lg .posts__card__footer {
        padding: 60px 50px; }
      .posts__card.card-lg .posts__card__body {
        text-align: center; }
        .posts__card.card-lg .posts__card__body h3 {
          font-size: 2.625rem; }
        .posts__card.card-lg .posts__card__body a {
          margin-top: -3.75rem;
          display: block; }
      .posts__card.card-lg .posts__card__dropdown svg path {
        fill: #cc2027; }
  .posts .last-row {
    margin-top: 72px; }
  .posts__bottom-link {
    margin-top: 72px;
    color: #fff;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9375rem 1.125rem;
    font-size: 17px;
    line-height: 1.22222;
    font-weight: 900;
    max-width: max-content;
    letter-spacing: 0.8px;
    transition: 250ms background cubic-bezier(0.3, 0, 0.22, 1), 250ms color cubic-bezier(0.3, 0, 0.22, 1); }
    .posts__bottom-link:hover {
      color: #fff;
      background: #131313; }
    .posts__bottom-link::after {
      content: "+";
      position: relative;
      margin-left: 5px;
      font-weight: 400;
      font-size: 19px; }

@media screen and (max-width: 991px) {
  .posts__first-row {
    overflow: visible;
    padding-top: 4.6875rem; }
    .posts__first-row::before {
      left: calc(50% - 335px);
      height: 46.875rem; }
    .posts__first-row::after {
      right: 1.5625rem;
      height: 37.5rem;
      width: 8.75rem;
      top: 12.5rem; }
  .posts__title-block {
    margin-bottom: 2.5rem; }
  .posts__subheading {
    margin-bottom: 0.9375rem;
    font-size: 14px; }
  .posts .last-row {
    margin-top: 0; }
  .posts__card {
    margin-bottom: 20px; }
    .posts__card__body h3 {
      font-size: 1.5rem; } }

@media screen and (max-width: 767px) {
  .posts {
    overflow: hidden;
    padding-bottom: 3.5rem; }
    .posts__first-row {
      overflow: visible; }
      .posts__first-row::before {
        height: 46.875rem;
        left: 0; }
      .posts__first-row::after {
        left: 0;
        height: 46.875rem;
        top: 12.5rem;
        right: 0.3125rem;
        width: 100%; }
    .posts__heading {
      font-size: 34px; } }

.two-box {
  position: relative; }
  .two-box.has-bg-effect::before {
    content: "";
    position: absolute;
    z-index: -1;
    right: calc(50% - 684px);
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.02);
    bottom: -5.625rem;
    height: 100%;
    max-width: 100%; }
  .two-box.has-bg-effect::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 57.1875rem;
    bottom: -5.625rem;
    right: 0;
    top: 0;
    background: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}") repeat;
    background-size: 2.5rem 2.5rem;
    height: 100%; }
  .two-box__box {
    width: calc(100% + 50px);
    padding: 3.75rem;
    font-weight: 900;
    position: relative;
    text-align: center;
    box-shadow: 0.0625rem -0.25rem 5.9375rem rgba(0, 0, 0, 0.15); }
    .two-box__box.box-right {
      margin: 6.125rem 0 0 -3.125rem; }
    .two-box__box.box-left::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: 70%;
      left: -15%;
      top: 25%;
      bottom: 20%;
      background: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}") repeat;
      background-size: 2.5rem 2.5rem; }
    .two-box__box__subheading {
      opacity: 0.7;
      margin-bottom: 0.0625rem;
      font-size: 15px;
      letter-spacing: 0.3em;
      line-height: 1.75; }
    .two-box__box__heading {
      margin: 0 auto 1.25rem;
      max-width: 21.25rem;
      letter-spacing: 0.4px; }
    .two-box__box__link {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      font-size: 1.125rem;
      line-height: 1.22222;
      border-radius: 0;
      transition: opacity 0.2s cubic-bezier(0.3, 0, 0.22, 1); }
      .two-box__box__link:hover {
        opacity: 0.8; }
      .two-box__box__link svg {
        margin-bottom: 4px;
        margin-left: 5px; }

@media screen and (max-width: 1279px) {
  .two-box.has-bg-effect::before {
    max-width: 100%; } }

@media screen and (max-width: 991px) {
  .two-box__box {
    padding: 3.75rem 2.5rem;
    width: 100%; }
    .two-box__box.box-left {
      margin-left: 1.5625rem; }
    .two-box__box.box-right {
      margin: -1.5625rem 1.5625rem 0 0; }
    .two-box__box__heading {
      max-width: 100%; } }

.solutions-list {
  padding: 90px 0; }
  .solutions-list__filter-slider {
    margin-bottom: 4.5rem; }
    .solutions-list__filter-slider::after {
      content: "";
      width: 1px;
      height: 1.875rem;
      position: absolute;
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(38, 38, 38, 0.3); }
    .solutions-list__filter-slider::before {
      content: "";
      width: 1px;
      height: 1.875rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(38, 38, 38, 0.3); }
  .solutions-list__slider-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 33px; }
    .solutions-list__slider-navigation .slide-btn {
      margin: 0 10px;
      cursor: pointer;
      width: 29px;
      height: 34px; }
      .solutions-list__slider-navigation .slide-btn svg {
        object-fit: contain;
        width: 100%;
        height: 100%; }
        .solutions-list__slider-navigation .slide-btn svg path {
          stroke: #000; }
      .solutions-list__slider-navigation .slide-btn.slide-prev {
        transform: rotateY(180deg); }
      .solutions-list__slider-navigation .slide-btn.swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none; }
  .solutions-list__filter {
    width: max-content !important;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    color: rgba(51, 51, 51, 0.6);
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
    word-spacing: 3px;
    font-weight: 500;
    transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1);
    cursor: pointer; }
    .solutions-list__filter:hover {
      color: #262626; }
    .solutions-list__filter__holder {
      position: relative; }
      .solutions-list__filter__holder::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0; }
      .solutions-list__filter__holder::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        border-width: 0 0 2px 2px;
        opacity: 0; }
    .solutions-list__filter span {
      padding: 0.75rem 1.25rem;
      display: inline-block;
      vertical-align: top;
      position: relative; }
      .solutions-list__filter span::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 0;
        border-width: 2px 2px 0 0;
        opacity: 0; }
      .solutions-list__filter span::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        position: absolute;
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
        border-width: 2px 0 0 2px;
        opacity: 0; }
    .solutions-list__filter.active {
      font-style: italic;
      font-weight: 900;
      color: #262626; }
      .solutions-list__filter.active .solutions-list__filter__holder::after, .solutions-list__filter.active .solutions-list__filter__holder::before {
        opacity: 1; }
      .solutions-list__filter.active span {
        letter-spacing: 0.05em; }
        .solutions-list__filter.active span::after, .solutions-list__filter.active span::before {
          opacity: 1; }
  .solutions-list__subheading {
    color: #cc2027;
    letter-spacing: 0.3em;
    opacity: 0.5;
    display: block;
    margin-bottom: 0.625rem;
    font-size: 18px; }
  .solutions-list__heading {
    color: #cc2027;
    margin-bottom: 1.5rem;
    max-width: 585px; }
  .solutions-list__description {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #777777; }
  .solutions-list .solution-col {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    display: none; }
    .solutions-list .solution-col.show {
      display: block; }
  .solutions-list .no-results {
    display: none; }
    .solutions-list .no-results p {
      margin: 41px 0;
      text-align: center;
      font-size: 36px;
      font-weight: 500; }
  .solutions-list__items {
    position: relative; }
    .solutions-list__items::before {
      content: "";
      position: absolute;
      left: -9999px;
      right: 35%;
      top: 2.5rem;
      bottom: -9.625rem;
      background-color: rgba(0, 0, 0, 0.04);
      background-image: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}");
      background-repeat: repeat;
      background-size: 40px 40px;
      background-position: 35px 35px;
      z-index: -1; }
    .solutions-list__items .row {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important; }
    .solutions-list__items.no-items::before {
      display: none; }
    .solutions-list__items.no-items .no-results {
      display: block; }
  .solutions-list__item {
    display: block;
    transition: background 0.2s linear, color 0.2s linear;
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -0.004em;
    padding: 1.4375rem 0.9375rem;
    width: 100%;
    min-height: 18.75rem;
    box-shadow: 4px 0 95px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #cc2027;
    color: #fff;
    cursor: pointer; }
    .solutions-list__item__categories {
      display: none; }
    .solutions-list__item__description {
      min-height: 8.75rem;
      max-height: 8.75rem;
      max-width: 18rem;
      position: relative;
      overflow: hidden;
      margin-bottom: 2.375rem;
      margin: 0 auto;
      opacity: 0;
      text-align: center; }
    .solutions-list__item__description-holder {
      min-height: 8.75rem;
      max-height: 8.75rem;
      position: relative;
      overflow: hidden; }
      .solutions-list__item__description-holder::after {
        width: 5rem;
        height: 1px;
        content: "";
        background: #cc2027;
        position: absolute;
        bottom: 1.75rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0; }
    .solutions-list__item__title {
      color: inherit;
      font-size: 1.875rem;
      line-height: 1.17;
      font-weight: 900;
      letter-spacing: normal;
      margin: 0 0 1.375rem;
      min-height: 4.375rem;
      text-align: center; }
    .solutions-list__item__icon-holder {
      width: 100%;
      height: 8.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0; }
      .solutions-list__item__icon-holder i {
        display: inline-block;
        vertical-align: top;
        font-size: 3.75rem;
        line-height: 1;
        color: inherit; }
    .solutions-list__item.white-grey {
      background-color: #fff;
      color: #333; }
      .solutions-list__item.white-grey .solutions-list__item__description-holder::after {
        background: #fff; }
    .solutions-list__item.white-red {
      background-color: #fff;
      color: #cc2027; }
      .solutions-list__item.white-red .solutions-list__item__description-holder::after {
        background: #fff; }
    .solutions-list__item.grey {
      background-color: #333;
      color: #fff; }
      .solutions-list__item.grey .solutions-list__item__description-holder::after {
        background: #333; }
    .solutions-list__item:hover {
      background-color: #fff;
      color: #cc2027;
      margin: -25px;
      width: calc(100% + 50px);
      min-height: 21.875rem;
      padding: 2.3125rem 2.1875rem 1.25rem; }
      .solutions-list__item:hover .solutions-list__item__description-holder {
        min-height: 12.5rem;
        max-height: none; }
        .solutions-list__item:hover .solutions-list__item__description-holder::after {
          opacity: 1; }
      .solutions-list__item:hover .solutions-list__item__icon-holder {
        opacity: 0;
        transform: translateY(100%); }
      .solutions-list__item:hover .solutions-list__item__description {
        opacity: 1; }
      .solutions-list__item:hover.white-grey {
        background-color: #333;
        color: #fff; }
      .solutions-list__item:hover.white-red {
        background-color: #cc2027;
        color: #fff; }
      .solutions-list__item:hover.grey {
        background-color: #fff;
        color: #333; }
  .solutions-list__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px; }
    .solutions-list__pagination.hidden {
      display: none; }
    .solutions-list__pagination__big-btn {
      position: relative;
      display: block;
      padding: 16px 20px;
      background: #262626;
      color: #fff;
      line-height: 1;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: -1px;
      word-spacing: 2px;
      font-size: 1.25rem;
      transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
      .solutions-list__pagination__big-btn:hover {
        opacity: 0.8; }
      .solutions-list__pagination__big-btn svg {
        width: 16px;
        height: 18px; }
      .solutions-list__pagination__big-btn.disabled {
        opacity: 0.8;
        pointer-events: none;
        font-weight: 900; }
    .solutions-list__pagination__pages {
      display: flex;
      align-items: center;
      justify-content: center; }
    .solutions-list__pagination__page {
      padding: 16px;
      font-weight: 500;
      font-size: 18px;
      color: #777777;
      cursor: pointer; }
      .solutions-list__pagination__page:hover {
        color: #8a161a; }
    .solutions-list__pagination__prev svg {
      transform: rotateY(180deg);
      margin-right: 6px; }
    .solutions-list__pagination__next svg {
      margin-left: 6px; }

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 3;
  display: none; }
  .loading-overlay.active {
    display: flex; }
  .loading-overlay img {
    filter: invert(1);
    opacity: 0.7; }

@media screen and (max-width: 1279px) {
  .solutions-list .solution-col {
    padding: 2.1875rem 0.5rem !important; }
  .solutions-list__item:hover {
    margin: -10px;
    width: calc(100% + 20px);
    min-height: 18.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; } }

@media screen and (max-width: 991px) {
  .solutions-list {
    padding-top: 30px; } }

@media screen and (max-width: 767px) {
  .solutions-list .solution-col {
    padding: 0.5rem !important; } }

.text-cols {
  padding: 90px 0 25px; }
  .text-cols__heading {
    margin-bottom: 24px;
    color: #cc2027; }
  .text-cols__col {
    color: #777777;
    line-height: 1.77778; }
    .text-cols__col p {
      font-size: 18px; }

@media (max-width: 991px) {
  .text-cols__heading {
    margin-bottom: 0; }
  .text-cols__col {
    margin-top: 30px; } }

.benefits {
  overflow: hidden; }
  .benefits__holder {
    padding-top: 80px;
    padding-bottom: 60px;
    position: relative;
    background-color: #333333; }
    .benefits__holder::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -9999px;
      right: -16%;
      background: #333333;
      z-index: -1; }
  .benefits__subheading {
    width: max-content !important;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    color: #fff;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
    word-spacing: 3px;
    font-style: italic;
    font-weight: 900;
    transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1);
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-bottom: 48px; }
    .benefits__subheading__holder {
      position: relative; }
      .benefits__subheading__holder::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #fff;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: 0; }
      .benefits__subheading__holder::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #fff;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        border-width: 0 0 2px 2px; }
    .benefits__subheading span {
      padding: 0.75rem 1.25rem;
      display: inline-block;
      vertical-align: top;
      position: relative;
      letter-spacing: 0.05em; }
      .benefits__subheading span::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #fff;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 0;
        border-width: 2px 2px 0 0; }
      .benefits__subheading span::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #fff;
        position: absolute;
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
        border-width: 2px 0 0 2px; }
  .benefits__heading {
    padding: 0 6.25rem 2.625rem;
    font-size: 2rem;
    line-height: 1.09;
    margin-bottom: 0;
    font-weight: 900;
    color: #fff;
    text-align: center; }
  .benefits__item {
    max-width: 20.25rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .benefits__item img {
      margin-bottom: 28px; }
    .benefits__item__title {
      color: #fff;
      margin-bottom: 30px;
      font-size: 23px;
      text-align: center;
      line-height: 1.09;
      font-weight: 900; }
  .benefits__slider-navigation {
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 33px;
    display: none; }
    .benefits__slider-navigation .slide-btn {
      margin: 0 10px;
      cursor: pointer;
      width: 29px;
      height: 34px; }
      .benefits__slider-navigation .slide-btn svg {
        object-fit: contain;
        width: 100%;
        height: 100%; }
        .benefits__slider-navigation .slide-btn svg path {
          stroke: #fff; }
      .benefits__slider-navigation .slide-btn.slide-prev {
        transform: rotateY(180deg); }
      .benefits__slider-navigation .slide-btn.swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none; }

@media (max-width: 575px) {
  .benefits__holder::before {
    right: -9999px; }
  .benefits__heading {
    padding: 0px 0rem 1.25rem; }
  .benefits__slider-navigation {
    display: flex; } }

.related-posts {
  padding: 55px 0 120px;
  overflow: hidden; }
  .related-posts .container {
    position: relative; }
    .related-posts .container::before {
      content: "";
      position: absolute;
      left: -9999px;
      top: -6.875rem;
      right: 35.5%;
      bottom: -25.25rem;
      background-repeat: repeat;
      background-size: 40px 40px;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.04);
      background-position: 35px 35px; }
    .related-posts .container::after {
      content: "";
      position: absolute;
      left: 2.1875rem;
      right: -9999px;
      top: 6.875rem;
      bottom: -31.875rem;
      background-image: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}");
      background-repeat: repeat;
      background-size: 40px 40px;
      z-index: -1; }
  .related-posts__subheading {
    margin-bottom: 13px;
    letter-spacing: 0.3em;
    font-size: 18px;
    color: #4c4c4c;
    font-weight: 900; }
  .related-posts__heading {
    margin-bottom: 40px;
    color: #4c4c4c;
    font-weight: 900;
    line-height: 1.1;
    font-weight: 900;
    font-size: 50px; }

@media (max-width: 767px) {
  .related-posts__subheading {
    margin-bottom: 0.9375rem;
    font-size: 0.75rem; }
  .related-posts__heading {
    margin-bottom: 1.4375rem;
    line-height: 1.1;
    font-weight: 900;
    font-size: 34px; }
  .related-posts__slider {
    overflow: visible !important; }
  .related-posts .posts__bottom-link {
    margin-top: 36px; } }

.text-form {
  padding: 50px 0; }
  .text-form__text {
    color: #777777;
    line-height: 1.77778; }
    .text-form__text p {
      font-size: 18px; }
  .text-form__form {
    background: #ffffff;
    border: 1px solid #f0eff4;
    box-shadow: 0px 4px 15px rgba(196, 192, 217, 0.1);
    border-radius: 8px;
    padding: 54px 35px 39px 35px; }
    .text-form__form .hs-form-field {
      margin-bottom: 35px; }
      .text-form__form .hs-form-field label {
        color: #24344e; }
      .text-form__form .hs-form-field input::placeholder {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #a9a4c4; }
      .text-form__form .hs-form-field input,
      .text-form__form .hs-form-field textarea,
      .text-form__form .hs-form-field select {
        outline: none; }
      .text-form__form .hs-form-field .input {
        margin-top: 9px; }
        .text-form__form .hs-form-field .input input[type="text"],
        .text-form__form .hs-form-field .input input[type="email"],
        .text-form__form .hs-form-field .input textarea,
        .text-form__form .hs-form-field .input select {
          width: 100%;
          border: none;
          border-bottom: 1px solid #a8a4c4;
          padding-bottom: 10px; }
        .text-form__form .hs-form-field .input select {
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #a9a4c4; }
          .text-form__form .hs-form-field .input select option {
            color: #404a63; }
        .text-form__form .hs-form-field .input ul {
          padding-left: 0; }
        .text-form__form .hs-form-field .input .hs-form-radio {
          margin-bottom: 9px; }
          .text-form__form .hs-form-field .input .hs-form-radio .hs-form-radio-display {
            display: flex;
            align-items: center;
            position: relative; }
            .text-form__form .hs-form-field .input .hs-form-radio .hs-form-radio-display::after {
              content: "";
              display: block;
              position: absolute;
              width: 12px;
              height: 12px;
              left: 9px;
              top: 50%;
              transform: translate3D(-50%, -50%, 0);
              background: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.23828 5.56022L4.03974 8.36168L10.7632 1.63818' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") no-repeat center center/contain; }
          .text-form__form .hs-form-field .input .hs-form-radio input {
            width: 17px;
            height: 17px;
            margin: 0;
            border: 1px solid #a8a4c4; }
            .text-form__form .hs-form-field .input .hs-form-radio input:checked {
              background: #24344e;
              accent-color: #24344e; }
          .text-form__form .hs-form-field .input .hs-form-radio span {
            margin-left: 10px; }
      .text-form__form .hs-form-field .hs-form-booleancheckbox .hs-form-booleancheckbox-display {
        display: flex;
        align-items: center;
        position: relative; }
        .text-form__form .hs-form-field .hs-form-booleancheckbox .hs-form-booleancheckbox-display::after {
          content: "";
          display: block;
          position: absolute;
          width: 12px;
          height: 12px;
          left: 9px;
          top: 50%;
          transform: translate3D(-50%, -50%, 0);
          background: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.23828 5.56022L4.03974 8.36168L10.7632 1.63818' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") no-repeat center center/contain; }
      .text-form__form .hs-form-field .hs-form-booleancheckbox input {
        width: 17px;
        height: 17px;
        margin: 0;
        border: 1px solid #a8a4c4; }
        .text-form__form .hs-form-field .hs-form-booleancheckbox input:checked {
          background: #24344e;
          accent-color: #24344e; }
      .text-form__form .hs-form-field .hs-form-booleancheckbox span {
        margin-left: 10px; }
    .text-form__form ul {
      padding-left: 0;
      list-style: none; }
    .text-form__form .actions {
      display: flex;
      justify-content: center; }
      .text-form__form .actions input[type="submit"] {
        background: #cc2027;
        border-radius: 60px;
        padding: 12px 24px;
        border: none;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 250ms opacity cubic-bezier(0.3, 0, 0.22, 1); }
        .text-form__form .actions input[type="submit"]:hover {
          opacity: 0.8; }
    .text-form__form .hs-error-msg,
    .text-form__form .hs-error-msgs {
      color: #c51919 !important; }
    .text-form__form .hs-fieldtype-select {
      position: relative; }
      .text-form__form .hs-fieldtype-select::after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2231%22%20height%3D%2217%22%20viewBox%3D%220%200%2031%2017%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%201L15.5%2015L30%201%22%20stroke%3D%22%2324344E%22%20stroke-width%3D%222.5%22%2F%3E%0A%3C%2Fsvg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        right: 10px;
        top: 38px;
        width: 16px;
        height: 12px;
        pointer-events: none;
        z-index: 1; }

.contact {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem; }
  .contact__subheading {
    font-size: 1.125rem;
    display: block;
    margin-bottom: 0.625rem;
    opacity: 0.5;
    color: inherit;
    letter-spacing: 0.3em;
    color: #cc2027;
    font-weight: 900; }
  .contact__heading {
    font-size: 3.125rem;
    line-height: 1.1;
    color: #cc2027;
    margin-bottom: 1.5rem;
    font-weight: 900; }
  .contact__description {
    font-size: 1.125rem;
    line-height: 2.77778;
    color: #777777; }
  .contact__form {
    padding-top: 1.5625rem; }
    .contact__form form .hs-form-field label {
      display: none; }
      .contact__form form .hs-form-field label.hs-error-msg {
        display: block;
        color: #cc2027; }
    .contact__form form .hs-error-msgs {
      list-style: none;
      padding-left: 0; }
    .contact__form form fieldset {
      max-width: 100%;
      margin-bottom: 2.1875rem; }
    .contact__form form input[type="text"],
    .contact__form form input[type="email"],
    .contact__form form input[type="tel"],
    .contact__form form select,
    .contact__form form textarea {
      width: 98% !important;
      display: block;
      height: calc(1.22222em + 1.375rem + 2px);
      padding: 0.6875rem 1.125rem;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.22222;
      color: #777777;
      background-color: transparent;
      background-clip: padding-box;
      border: 1px solid #333333;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-color: transparent transparent #000;
      padding-left: 0;
      padding-right: 0;
      outline: none; }
      .contact__form form input[type="text"]::placeholder,
      .contact__form form input[type="email"]::placeholder,
      .contact__form form input[type="tel"]::placeholder,
      .contact__form form select::placeholder,
      .contact__form form textarea::placeholder {
        text-transform: uppercase; }
    .contact__form form textarea {
      min-height: 13.4375rem;
      height: 13.4375rem;
      padding: 2rem 2.3125rem;
      border: 1px solid #000; }
      .contact__form form textarea::placeholder {
        font-style: italic;
        text-transform: none; }
    .contact__form form select {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0 center/22px 11px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 12'%3e%3cpath fill='%23b7b7b7' d='M 1.5 0 L 11 9 L 20.5 0 L 22 1.5 L 11 12 L 0 1.5 Z'/%3e%3c/svg%3e");
      padding-right: 1.75rem;
      background-color: transparent;
      text-transform: uppercase; }
    .contact__form form .form-columns-1 input[type="text"],
    .contact__form form .form-columns-1 input[type="email"],
    .contact__form form .form-columns-1 input[type="tel"],
    .contact__form form .form-columns-1 select {
      width: 49% !important; }
    .contact__form form input[type="submit"] {
      display: inline-block;
      font-weight: 900;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.9375rem 1.125rem;
      font-size: 1.125rem;
      line-height: 1.22222;
      border-radius: 0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #fff;
      background-color: #cc2027;
      border-color: #cc2027;
      text-transform: uppercase;
      cursor: pointer;
      appearance: none;
      letter-spacing: 0.8px;
      -webkit-appearance: none;
      padding-right: 32px; }
      .contact__form form input[type="submit"]:hover {
        color: #fff;
        background-color: #ab1b21;
        border-color: #a0191f; }
    .contact__form form .actions {
      width: max-content;
      position: relative; }
      .contact__form form .actions::after {
        content: "";
        display: block;
        z-index: 1;
        background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2216%22%20height%3D%2210%22%20viewBox%3D%220%200%2016%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0.5%208.5H11.5L4.5%202%22%20stroke%3D%22white%22%20stroke-width%3D%223%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 12px;
        top: 19px;
        width: 16px;
        height: 13px; }

@media (max-width: 991px) {
  .contact__form fieldset {
    margin-bottom: 0 !important; }
  .contact__form .form-columns-2 .field,
  .contact__form .form-columns-1 .field {
    margin-bottom: 2rem;
    width: 100% !important;
    float: none; }
    .contact__form .form-columns-2 .field input[type="text"],
    .contact__form .form-columns-2 .field input[type="email"],
    .contact__form .form-columns-2 .field input[type="tel"],
    .contact__form .form-columns-2 .field select,
    .contact__form .form-columns-2 .field textarea,
    .contact__form .form-columns-1 .field input[type="text"],
    .contact__form .form-columns-1 .field input[type="email"],
    .contact__form .form-columns-1 .field input[type="tel"],
    .contact__form .form-columns-1 .field select,
    .contact__form .form-columns-1 .field textarea {
      width: 100% !important; } }

@media (max-width: 767px) {
  .contact {
    padding-top: 3.625rem; }
    .contact__subheading {
      margin-bottom: 0.625rem;
      font-size: 0.75rem; }
    .contact__heading {
      margin-bottom: 1.5625rem;
      font-size: 2.187rem; }
    .contact__description {
      line-height: 1.77778; }
      .contact__description p {
        margin-bottom: 1rem; } }

.offices__slider-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 33px;
  position: absolute;
  top: 0;
  display: none; }
  .offices__slider-navigation .slide-btn {
    margin: 0 10px;
    cursor: pointer;
    width: 29px;
    height: 34px; }
    .offices__slider-navigation .slide-btn svg {
      object-fit: contain;
      width: 100%;
      height: 100%; }
      .offices__slider-navigation .slide-btn svg path {
        stroke: #000; }
    .offices__slider-navigation .slide-btn.slide-prev {
      transform: rotateY(180deg); }
    .offices__slider-navigation .slide-btn.swiper-button-disabled {
      opacity: 0.3;
      pointer-events: none; }

.offices__areas {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 1.875rem;
  line-height: 1.17;
  padding-bottom: 4.375rem; }

.offices__area {
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
  width: auto;
  margin-bottom: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  position: relative;
  color: #4c4c4c;
  border: 1px solid #cfcfcf;
  transition: 250ms border ease-in-out, 250ms color ease-in-out, 250ms ease-in-out background;
  cursor: pointer; }
  .offices__area.active, .offices__area:hover {
    background: #cc2027;
    color: #fff;
    border: 1px solid #cc2027; }
  .offices__area.active {
    font-weight: 900;
    letter-spacing: 0.8px; }

.offices__cities {
  display: none;
  margin-bottom: 5rem;
  padding-top: 2.75rem !important; }
  .offices__cities .swiper-wrapper {
    justify-content: center; }
  .offices__cities.active {
    display: flex; }

.offices__city {
  width: max-content !important;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  color: rgba(51, 51, 51, 0.6);
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
  word-spacing: 3px;
  font-weight: 500;
  transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1);
  cursor: pointer; }
  .offices__city:hover {
    color: #262626; }
  .offices__city__holder {
    position: relative; }
    .offices__city__holder::before {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0; }
    .offices__city__holder::after {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      border-width: 0 0 2px 2px;
      opacity: 0; }
  .offices__city span {
    padding: 0.75rem 1.25rem;
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: max-content; }
    .offices__city span::before {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: 0;
      top: auto;
      bottom: 0;
      border-width: 2px 2px 0 0;
      opacity: 0; }
    .offices__city span::after {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      position: absolute;
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      border-width: 2px 0 0 2px;
      opacity: 0; }
  .offices__city.active {
    font-style: italic;
    font-weight: 900;
    color: #262626; }
    .offices__city.active .offices__city__holder::after, .offices__city.active .offices__city__holder::before {
      opacity: 1; }
    .offices__city.active span {
      letter-spacing: 0.05em; }
      .offices__city.active span::after, .offices__city.active span::before {
        opacity: 1; }

.offices__locations {
  display: none; }
  .offices__locations.active {
    display: block; }

.offices__location {
  display: none; }
  .offices__location iframe {
    width: 100%; }
  .offices__location.active {
    display: block; }

@media (min-width: 1279px) {
  .offices .container.wide-container {
    max-width: 1544px; } }

@media (max-width: 1279px) {
  .offices__areas {
    font-size: 1.25rem;
    line-height: 1.75; }
  .offices__area {
    padding: 0.9375rem; } }

@media (max-width: 991px) {
  .offices__slider-navigation {
    display: flex; }
  .offices__areas {
    flex-wrap: wrap; }
  .offices__area {
    width: 50%;
    flex-grow: 1;
    flex-basis: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .offices__cities .swiper-wrapper {
    justify-content: unset; }
  .offices__cities::after {
    content: "";
    width: 1px;
    height: 1.875rem;
    position: absolute;
    left: auto;
    right: 0;
    top: 80%;
    transform: translateY(-80%);
    background: rgba(38, 38, 38, 0.3); }
  .offices__cities::before {
    content: "";
    width: 1px;
    height: 1.875rem;
    position: absolute;
    left: 0;
    top: 80%;
    transform: translateY(-80%);
    background: rgba(38, 38, 38, 0.3); } }

@media (max-width: 767px) {
  .offices__areas {
    font-size: 1rem;
    line-height: 1.5;
    padding-bottom: 3rem; }
  .offices__area {
    width: 100%; }
  .offices__cities {
    margin-top: 0.75rem;
    margin-bottom: 3rem; } }

.about-controls {
  padding-top: 5.625rem;
  margin-bottom: 4.5rem; }
  .about-controls__slider-navigation {
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
    top: 0;
    display: none; }
    .about-controls__slider-navigation .slide-btn {
      margin: 0 10px;
      cursor: pointer;
      width: 29px;
      height: 34px; }
      .about-controls__slider-navigation .slide-btn svg {
        object-fit: contain;
        width: 100%;
        height: 100%; }
        .about-controls__slider-navigation .slide-btn svg path {
          stroke: #000; }
      .about-controls__slider-navigation .slide-btn.slide-prev {
        transform: rotateY(180deg); }
      .about-controls__slider-navigation .slide-btn.swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none; }
  .about-controls__slider .swiper-wrapper {
    justify-content: center; }
  .about-controls__slide {
    width: max-content !important;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    color: rgba(51, 51, 51, 0.6);
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
    word-spacing: 3px;
    font-weight: 500;
    transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1);
    cursor: pointer; }
    .about-controls__slide a {
      color: inherit; }
    .about-controls__slide:hover {
      color: #262626; }
    .about-controls__slide__holder {
      position: relative; }
      .about-controls__slide__holder::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0; }
      .about-controls__slide__holder::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        border-width: 0 0 2px 2px;
        opacity: 0; }
    .about-controls__slide span {
      padding: 0.75rem 1.25rem;
      display: inline-block;
      vertical-align: top;
      position: relative; }
      .about-controls__slide span::before {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 0;
        border-width: 2px 2px 0 0;
        opacity: 0; }
      .about-controls__slide span::after {
        width: 0.625rem;
        height: 0.625rem;
        content: "";
        border: 2px solid #262626;
        position: absolute;
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
        border-width: 2px 0 0 2px;
        opacity: 0; }
    .about-controls__slide.active {
      font-style: italic;
      font-weight: 900;
      color: #262626; }
      .about-controls__slide.active .about-controls__slide__holder::after, .about-controls__slide.active .about-controls__slide__holder::before {
        opacity: 1; }
      .about-controls__slide.active span {
        letter-spacing: 0.05em; }
        .about-controls__slide.active span::after, .about-controls__slide.active span::before {
          opacity: 1; }

@media (max-width: 991px) {
  .about-controls__slider::after {
    content: "";
    width: 1px;
    height: 1.875rem;
    position: absolute;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(38, 38, 38, 0.3); }
  .about-controls__slider::before {
    content: "";
    width: 1px;
    height: 1.875rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(38, 38, 38, 0.3); }
  .about-controls__slider .swiper-wrapper {
    justify-content: flex-start; }
  .about-controls__slider-navigation {
    display: flex; } }

@media (max-width: 767px) {
  .about-controls {
    padding-top: 3rem;
    margin-bottom: 2.9375rem; }
    .about-controls__slider-navigation {
      margin-bottom: 27px; } }

.overview {
  position: relative;
  padding-bottom: 1.5625rem; }
  .overview::before {
    width: 6.5rem;
    height: 10.8125rem;
    content: "";
    position: absolute;
    left: 0;
    top: 1.25rem;
    background-color: #262626;
    box-shadow: 0 4px 95px 0 rgba(0, 0, 0, 0.15); }
  .overview::after {
    width: 6.625rem;
    height: 34.5rem;
    content: "";
    position: absolute;
    left: 0;
    top: -2.5rem;
    background-image: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}");
    background-size: 40px 40px;
    background-position: 16px 10px; }
  .overview__subheading {
    display: block;
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    opacity: 0.5;
    color: inherit;
    letter-spacing: 0.3em;
    color: #262626;
    font-weight: 900; }
  .overview__heading {
    font-size: 3.125rem;
    line-height: 1.1;
    color: #262626;
    margin-bottom: 1.5rem;
    font-weight: 900; }
  .overview__text {
    color: #777777; }
    .overview__text * {
      line-height: 1.77778; }

@media (max-width: 1599px) {
  .overview::before, .overview::after {
    display: none; } }

@media (max-width: 767px) {
  .overview__subheading {
    display: block;
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    opacity: 0.5;
    color: inherit;
    letter-spacing: 0.3em; }
  .overview__heading {
    margin-bottom: 1.5625rem;
    font-size: 2.187rem; } }

.video {
  padding-top: 4.625rem;
  padding-bottom: 3.625rem;
  overflow: hidden; }
  .video .container {
    position: relative; }
    .video .container::before {
      content: "";
      position: absolute;
      top: 3.125rem;
      left: 40.3%;
      right: -9999px;
      bottom: -8.25rem;
      background: #cc2027;
      z-index: -1; }
  .video__content-video {
    width: 100%;
    margin: 0 0 2.875rem;
    position: relative;
    overflow: hidden;
    min-height: 28.75rem;
    background: #000;
    box-shadow: 0 4px 95px 0 rgba(0, 0, 0, 0.15);
    z-index: 3;
    margin-bottom: 1rem; }
  .video__thumbnail, .video__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-size: cover;
    width: 100%;
    height: 100%; }
  .video__video {
    z-index: -1; }
    .video__video.active {
      z-index: 3; }
    .video__video .oembed_container {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      height: 100% !important; }
    .video__video .iframe_wrapper {
      height: 100% !important; }
    .video__video iframe {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      height: 100% !important; }
  .video__play {
    transition: border-color 0.3s linear;
    width: 0;
    height: 0;
    display: block;
    margin: auto;
    font-size: 0;
    line-height: 0;
    border-left: 1.875rem solid #fff;
    border-top: 1.375rem solid transparent;
    border-bottom: 1.375rem solid transparent;
    border-left-width: 4.375rem;
    border-top-width: 2.8125rem;
    border-bottom-width: 2.8125rem;
    cursor: pointer; }
    .video__play:hover {
      border-left-color: #cc2027; }
  .video__text {
    color: #fff;
    position: relative;
    margin-bottom: 1rem; }
    .video__text::before {
      content: "";
      position: absolute;
      border-top: 0.125rem dotted #fff;
      opacity: 0.7;
      left: -1.875rem;
      top: -1.5625rem;
      width: 7.8125rem; }
    .video__text h3 {
      font-size: 1.875rem;
      line-height: 1.17;
      font-weight: 400;
      margin-bottom: 1.875rem; }
    .video__text p {
      line-height: 1.7778;
      font-size: 17px; }

@media (max-width: 991px) {
  .video {
    padding-bottom: 0; }
    .video .container::before {
      display: none; }
    .video__text {
      margin-top: 30px; }
      .video__text h3 {
        font-size: 1.5rem;
        line-height: 1.125; }
      .video__text p {
        font-size: 1.125rem;
        line-height: 1.3333; }
      .video__text::before {
        left: 0; }
      .video__text::after {
        content: "";
        position: absolute;
        top: -6.25rem;
        left: -9999px;
        right: -9999px;
        bottom: -1.5625rem;
        background: #cc2027;
        z-index: -1; } }

@media (max-width: 767px) {
  .video__content-video {
    min-height: 16.625rem; }
  .video__play {
    border-left: 1.875rem solid #fff;
    border-top: 1.375rem solid transparent;
    border-bottom: 1.375rem solid transparent; } }

.members {
  padding-bottom: 6.25rem;
  overflow: hidden; }
  .members .container.highlighted {
    position: relative; }
    .members .container.highlighted::before {
      content: "";
      position: absolute;
      right: -9999px;
      background: #cc2027;
      z-index: -2;
      background-image: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}");
      background-repeat: repeat;
      top: -11.8125rem;
      bottom: -6.25rem;
      left: 40.3%;
      background-size: 42px 42px;
      background-position: 19px 23px; }
  .members__slider-navigation {
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
    top: 92px;
    position: absolute;
    right: 43px;
    display: flex;
    z-index: 2; }
    .members__slider-navigation .slide-btn {
      margin: 0 10px;
      cursor: pointer;
      width: 29px;
      height: 34px; }
      .members__slider-navigation .slide-btn svg {
        object-fit: contain;
        width: 100%;
        height: 100%; }
        .members__slider-navigation .slide-btn svg path {
          stroke: #fff; }
      .members__slider-navigation .slide-btn.slide-prev {
        transform: rotateY(180deg); }
      .members__slider-navigation .slide-btn.swiper-button-disabled {
        opacity: 0.3;
        pointer-events: none; }
  .members__heading {
    margin-bottom: 2rem;
    font-weight: 900;
    color: #262626;
    font-size: 3.125rem;
    line-height: 1.1; }
  .members__profiles-slider {
    cursor: grab; }
  .members__profile {
    padding: 5rem 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .members__profile__img-holder {
      width: 140px;
      height: 140px;
      position: relative;
      transform: scale3d(1, 1, 1);
      transition: transform 0.3s linear; }
      .members__profile__img-holder img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .members__profile__img-holder::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(51, 51, 51, 0.5); }
    .members__profile__name {
      padding-top: 1.5625rem;
      margin-top: 0;
      font-size: 1.25rem;
      font-weight: 500;
      opacity: 0.5;
      text-transform: capitalize;
      color: #4c4c4c;
      line-height: 1.555; }
    .members__profile.swiper-slide-active .members__profile__name {
      display: none; }
    .members__profile.swiper-slide-active .members__profile__img-holder {
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
      transform: scale3d(1.93, 1.93, 1.93);
      z-index: 1; }
  .members__info-box {
    padding-top: 13.125rem;
    padding-bottom: 1.5625rem;
    background: #fff;
    box-shadow: 0 4px 95px 0 rgba(0, 0, 0, 0.15);
    max-width: 1590px;
    margin: 0 auto;
    margin-top: -12.625rem; }
  .members__info {
    display: none;
    opacity: 0;
    z-index: -2;
    position: relative;
    transition: 250ms opacity linear, 250ms z-index linear; }
    .members__info.active {
      opacity: 1;
      z-index: 1;
      display: block; }
    .members__info__name {
      font-size: 1.875rem;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 1.1;
      color: #cc2027;
      letter-spacing: 0.8; }
    .members__info__role {
      padding-top: 1.25rem;
      font-size: 0.9375rem;
      padding-bottom: 2.5rem;
      line-height: 1.5;
      margin-bottom: 2.3125rem;
      opacity: 0.5;
      color: inherit;
      letter-spacing: 0.3em;
      text-transform: capitalize;
      font-weight: 900;
      color: #777777; }
    .members__info__text {
      font-size: 1.125rem;
      color: #777777; }
      .members__info__text * {
        line-height: 1.78; }
      .members__info__text p {
        margin-bottom: 1.875rem; }

@media (max-width: 1279px) {
  .members__slider-navigation {
    top: 55px; }
  .members__profile {
    padding: 0;
    margin: 0; }
    .members__profile__img-holder {
      transform: scale3d(0.63, 0.63, 0.63); }
    .members__profile__name {
      padding-top: 0;
      font-size: 1.25rem;
      max-width: 80%;
      text-align: center; }
    .members__profile.swiper-slide-active .members__profile__img-holder {
      transform: scale3d(1, 1, 1); }
  .members__info-box {
    margin-top: -7.625rem; } }

@media (max-width: 991px) {
  .members {
    padding-top: 2rem; }
    .members .container.highlighted::before {
      top: -13.75rem;
      left: -9999px;
      right: -9999px;
      bottom: 6.25rem;
      background-color: #cc2027;
      z-index: -2;
      background-repeat: repeat;
      background-size: 25px;
      background-position: 19px 23px; }
    .members__profile__name {
      font-size: 0.875rem; } }

@media (max-width: 767px) {
  .members {
    padding-top: 2rem;
    padding-bottom: 2.25rem; }
    .members .container.highlighted {
      padding-left: 0;
      padding-right: 0; }
    .members__heading {
      padding-right: 30px;
      padding-left: 30px;
      font-size: 2.187rem;
      margin-bottom: 3rem; }
    .members__slider-navigation {
      top: 44px; }
    .members__info-box {
      padding-top: 8.125rem;
      box-shadow: none; }
    .members__info__name {
      font-size: 1.5rem; }
    .members__info__role {
      padding-top: 0.625rem;
      font-size: 0.875rem;
      line-height: 1.4;
      margin-bottom: 0.9375rem;
      padding-bottom: 0; }
    .members__info__text p {
      margin-bottom: 1rem; } }

.partners .container.highlighted {
  position: relative;
  padding-bottom: 5.625rem; }
  .partners .container.highlighted::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: -9999px;
    top: 5.375rem;
    right: 32.6%;
    bottom: 0;
    background: #f0f0f0; }

.partners__slider-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 19px;
  display: none; }
  .partners__slider-navigation .slide-btn {
    margin: 0 10px;
    cursor: pointer;
    width: 29px;
    height: 34px; }
    .partners__slider-navigation .slide-btn svg {
      object-fit: contain;
      width: 100%;
      height: 100%; }
      .partners__slider-navigation .slide-btn svg path {
        stroke: #000; }
    .partners__slider-navigation .slide-btn.slide-prev {
      transform: rotateY(180deg); }
    .partners__slider-navigation .slide-btn.swiper-button-disabled {
      opacity: 0.3;
      pointer-events: none; }

.partners__subheading {
  display: block;
  margin-bottom: 0.625rem;
  opacity: 0.5;
  color: inherit;
  letter-spacing: 0.3em;
  font-size: 1.125rem;
  font-weight: 900;
  color: #262626; }

.partners__heading {
  font-weight: 900;
  color: #262626;
  margin-bottom: 2rem;
  font-size: 3.125rem;
  line-height: 1.1;
  letter-spacing: 0.8px; }

.partners__types-slider {
  margin-top: 4.5rem;
  margin-bottom: 7.5rem; }
  .partners__types-slider .swiper-wrapper {
    justify-content: center; }

.partners__type {
  width: max-content !important;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  color: rgba(51, 51, 51, 0.6);
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.01em;
  word-spacing: 3px;
  font-weight: 500;
  transition: 250ms color cubic-bezier(0.3, 0, 0.22, 1);
  cursor: pointer; }
  .partners__type:hover {
    color: #262626; }
  .partners__type__holder {
    position: relative; }
    .partners__type__holder::before {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0; }
    .partners__type__holder::after {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      border-width: 0 0 2px 2px;
      opacity: 0; }
  .partners__type span {
    padding: 0.75rem 1.25rem;
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .partners__type span::before {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: 0;
      top: auto;
      bottom: 0;
      border-width: 2px 2px 0 0;
      opacity: 0; }
    .partners__type span::after {
      width: 0.625rem;
      height: 0.625rem;
      content: "";
      border: 2px solid #262626;
      position: absolute;
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      border-width: 2px 0 0 2px;
      opacity: 0; }
  .partners__type.active {
    font-style: italic;
    font-weight: 900;
    color: #262626; }
    .partners__type.active .partners__type__holder::after, .partners__type.active .partners__type__holder::before {
      opacity: 1; }
    .partners__type.active span {
      letter-spacing: 0.05em; }
      .partners__type.active span::after, .partners__type.active span::before {
        opacity: 1; }

.partners__main-slider {
  display: none; }
  .partners__main-slider.active {
    display: block; }

.partners__main-slide__holder {
  box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.partners__main-slide__img-holder {
  padding: 2.5rem;
  background: url("{{ get_asset_url('../icons/svg/cross-pattern.svg') }}") repeat;
  background-size: 2.5rem 2.5rem;
  min-height: 155px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .partners__main-slide__img-holder img {
    max-width: 100%;
    height: auto; }

.partners__main-slide__body {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.75;
  font-weight: 300;
  height: 100%;
  min-height: 20.625rem;
  margin: 0 1.875rem 3.375rem 0;
  padding: 2.6875rem 1.875rem 4.375rem;
  color: #fff;
  background-color: #262626; }
  .partners__main-slide__body::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 3.375rem;
    background: #262626; }
  .partners__main-slide__body a {
    color: #fff;
    background-color: #cc2027;
    border-color: #cc2027;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    font-weight: 900;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.9375rem 1.125rem;
    padding-left: 2.1875rem;
    font-size: 1.125rem;
    line-height: 1.22222;
    text-transform: uppercase;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    left: 0;
    bottom: 0; }
    .partners__main-slide__body a:hover {
      color: #fff;
      background-color: #ab1b21;
      border-color: #a0191f; }
    .partners__main-slide__body a::after {
      content: "";
      display: block;
      z-index: 1;
      background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2216%22%20height%3D%2210%22%20viewBox%3D%220%200%2016%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0.5%208.5H11.5L4.5%202%22%20stroke%3D%22white%22%20stroke-width%3D%223%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      width: 16px;
      height: 13px;
      margin-left: 7px; }

@media (max-width: 991px) {
  .partners__slider-navigation.main-navigation {
    display: flex; } }

@media (max-width: 767px) {
  .partners .container.highlighted::before {
    display: none; }
  .partners__slider-navigation.types-navigation {
    display: flex; }
  .partners__types-slider {
    margin-top: 2.5rem;
    margin-bottom: 4.5rem; }
  .partners__subheading {
    margin-bottom: 0.625rem;
    font-size: 0.75rem; }
  .partners__heading {
    margin-bottom: 1.5625rem;
    font-size: 2.187rem; }
  .partners__types-slider .swiper-wrapper {
    justify-content: flex-start; }
  .partners__main-slide__body {
    margin: 0 0.9375rem 3.125rem 0;
    padding: 2.6875rem 1.875rem 4.375rem 0.9375rem;
    font-size: 1.125rem;
    line-height: 1.75;
    font-weight: 300; } }
